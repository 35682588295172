:root {
  --blue: #1058ff;
  --navy: #0b3fb8;
  --dark-blue: #082c7f;
  --light-blue: #ecf2ff;
  --pastel-blue: #aebad5;
  --white: #ffffff;
  --light: #fafcff;
  --light2: #f5f8ff;
  --light-grey: #ebeef5;
  --black: #000000;
  --red: #eb1414;
  --ceil: #889ac2;
  --gainsboro: #d7ddea;
  --azure: #d8e4ff;
}

body {
  margin: 0;
  font-family: "Figtree", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.55em;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  cursor: pointer;
}

a {
  color: var(--blue);
}

a:hover {
  color: var(--navy);
}

a:active,
a:focus {
  color: var(--dark-blue);
}

b,
h1,
h2,
h3 {
  font-weight: 600;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol {
  padding-left: 23px;
}

ol li {
  padding-left: 10px;
  margin-bottom: 40px;
}

p {
  margin: 40px 0;
}

input {
  width: 480px;
  height: 40px;
  border: 1px solid var(--pastel-blue);
  padding: 11px 24px;
  font-size: 15px;
  border-radius: 40px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  vertical-align: top;
  outline: 3px solid transparent;
}

input:hover {
  border-color: var(--blue);
  outline: 3px solid var(--light-blue);
}

input:focus {
  border-color: var(--blue);
}

input:active {
  border-color: var(--blue);
}

input.error {
  border-color: var(--red);
}

input::placeholder {
  color: var(--ceil);
}

.form-button {
  background: var(--blue);
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  padding: 11px 24px;
  border-radius: 40px;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.form-button:hover,
.form-button:active {
  background: var(--navy);
}
.form-button:disabled {
  cursor: auto;
}
.form-button:disabled.form-button:hover {
  background: var(--blue);
}
.form-button:disabled.form-button:active {
  background: var(--blue);
}
.form-button:disabled {
  cursor: auto;
}
.link-button {
  font-size: 15px;
  color: var(--ceil);
}

.link-button:hover {
  color: var(--dark-blue);
}

.input-wrapper {
  position: relative;
}

.error-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  right: 13px;
  width: 24px;
  height: 24px;
  background: url(/src/assets/icons/icon_warning.svg);
}

.status-text {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  margin-left: 25px;
}

.search-main .status-text {
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .status-text {
    position: static;
    margin-top: -5px;
  }
}

.text-error {
  color: var(--red);
}

.text-success {
  color: var(--blue)
}

hr {
  max-width: 1730px;
  margin: 0 100px;
  height: 0;
  border: 0;
  border-top: 1px solid var(--gainsboro);
}

@media only screen and (max-width: 1200px) {
  hr {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 1024px) {
  hr {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 576px) {
  hr {
    margin: 0 16px;
  }
}

.article-page-wrapper hr {
  margin: 0;
}

.block-divider {
  position: relative;
  text-align: center;
  background: var(--white);
}

.block-divider img {
  position: relative;
  background: var(--white);
  padding: 0 80px;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  .block-divider img {
    padding: 0 30px;
  }
}

.block-divider:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  top: 50%;
  left: 100px;
  right: 100px;
  border-top: 1px solid var(--azure);
}

@media only screen and (max-width: 1024px) {
  .block-divider:after {
    left: 40px;
    right: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .block-divider:after {
    left: 16px;
    right: 16px;
  }
  .block-divider img {
    width: 160px;
    height: 160px;
  }
}

.container {
  position: relative;
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 20px;
  }
}

footer .container {
  max-width: 1420px;
}

@media only screen and (max-width: 1200px) {
  .container,
  footer .container {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 1024px) {
  .container,
  footer .container {
    max-width: 942px;
  }
}

@media only screen and (max-width: 768px) {
  .container,
  footer .container {
    max-width: 688px;
  }
}

.landing-wrapper {
  position: relative;
}

.header {
  position: relative;
}

.menu-open {
  position: absolute;
  width: 24px;
  height: 24px;
}

.header .menu-open {
  display: none;
  left: 16px;
  top: 16px;
  z-index: 1;
}

@media only screen and (max-width: 568px) {
  .header .menu-open {
    display: block;
  }
}

.menu {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 0 auto;
}

.menu li {
  margin: 0 32px;
}

@media only screen and (max-width: 1200px) {
  .menu li {
    margin: 0 20px;
  }
}

.header .menu {
  width: 710px;
}

@media only screen and (max-width: 768px) {
  .header .menu {
    width: 488px;
  }
}

@media only screen and (max-width: 576px) {
  .header .menu {
    display: none;
  }
}

.menu li a {
  color: var(--black);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}

.menu li a:hover {
  color: var(--blue);
}

@media only screen and (max-width: 376px) {
  .menu li a {
    font-size: 13px;
  }
}

.block-header {
  font-size: 40px;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  color: var(--blue);
  text-align: center;
}

@media only screen and (max-width: 576px) {
  .block-header {
    font-size: 28px;
  }
}

.sub-header {
  font-family: "Lora", serif;
  font-size: 24px;
  font-weight: 400;
  color: var(--dark-blue);
  text-align: center;
  margin: 20px 0;
}

@media only screen and (max-width: 576px) {
  .sub-header {
    font-size: 20px;
    margin: 10px 0;
  }
}

.main {
  padding: calc((100vh - 580px)/2) 0 10px;
}

.main-gap {
  height: calc((100vh - 380px)/2);
  background: var(--white);
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 120px 0 10px;
  }
  .main-gap {
    height: calc(100vh - 930px);
  }
}

@media only screen and (max-width: 576px) {
  .main {
    padding: 70px 0 10px;
  }
  .main-gap {
    height: calc(100vh - 750px);
  }
}

.header,
.main {
  position: relative;
  z-index: 100;
  background: var(--white);
}

.main .portrait {
  position: absolute;
  top: -100px;
  right: 0;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  .main .portrait {
    position: static;
    top: 0;
    z-index: 0;
    display: block;
    margin: 0 auto -80px;
    width: 100%;
    max-width: 424px;
  }
}

@media only screen and (max-width: 576px) {
  .main .container {
    display: flex;
    flex-direction: column;
  }
  .main .portrait {
    margin: 0 auto;
    order: 2;
  }
  .main .search-main {
    order: 3;
    margin: 20px 0 40px;
  }
}

section .block-header {
  margin: 0 0 60px;
}

section .sub-header {
  margin-bottom: 16px;
}

.block .container {
  background: var(--white);
  padding: 160px 40px;
}

@media only screen and (max-width: 576px) {
  .block .container {
    padding: 80px 16px;
  }
}

.block-border {
  text-align: center;
}

.search-main {
  display: flex;
  position: relative;
  margin: 60px auto;
  justify-content: center;
  text-align: center;
  z-index: 1;
  max-width: 670px;
}

.search-main input {
  width: 100%;
  height: 48px;
  padding: 15px 24px;
  border-radius: 40px 0 0 40px;
}

@media only screen and (max-width: 768px) {
  .search-main input {
    height: 40px;
  }
}

.search-main button {
  margin-left: -1px;
  padding: 15px 24px;
  border-radius: 0 50px 50px 0;
}

@media only screen and (max-width: 768px) {
  .search-main button {
    padding: 11px 24px;
  }
}

.block-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: -15px;
}

@media only screen and (max-width: 768px) {
  .block-list {
    margin: 0;
  }
}

.block-item {
  background: var(--light);
  padding: 40px;
  max-width: 335px;
  min-width: 255px;
  margin: 15px;
  border-radius: 24px;
}

@media only screen and (max-width: 1200px) {
  .block-list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .block-item {
    padding: 24px;
  }
}

@media only screen and (max-width: 376px) {
  .block-item {
    min-width: 255px;
  }
}

.block-item b {
  font-size: 22px;
}

@media only screen and (max-width: 376px) {
  .block-item b {
    font-size: 20px;
  }
}

.block-item p {
  margin: 15px 0 0;
}

@media only screen and (max-width: 376px) {
  .block-item p {
    font-size: 16px;
  }
}

.block-item .block-icon {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.block-item_header {
  display: flex;
  align-items: center;
}

.icon-ask {
  background: url(assets/icons/icon_ask.svg) no-repeat;
}

.icon-learn {
  background: url(assets/icons/icon_learn.svg) no-repeat;
}

.icon-source {
  background: url(assets/icons/icon_source.svg) no-repeat;
}

.icon-curiosity {
  background: url(assets/icons/icon_curiousity.svg) no-repeat;
}

.icon-research {
  background: url(assets/icons/icon_research.svg) no-repeat;
}

.icon-write {
  background: url(assets/icons/icon_write.svg) no-repeat;
}

.manuscript-block {
  position: sticky;
  top: 120px;
  margin-top: 50px;
  width: 100%;
  max-width: 430px;
  max-height: 420px;
  text-align: center;
}

.block-articles .container {
  display: flex;
}

@media only screen and (max-width: 1250px) {
  .block-articles .container {
    flex-direction: column;
  }
  .manuscript-block {
    position: static;
    top: 0;
    margin: 0 auto;
  }

  .articles-wrapper {
    margin: 0 auto;
  }

  .block-articles .container:before,
  .block-articles .container:after {
    display: none;
  }
}

.articles-wrapper {
  width: 706px;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .articles-wrapper {
    width: auto;
    min-width: 342px;
    max-width: 100%;
  }
}

.articles-categories {
  display: flex;
  padding: 50px 0;
  margin: 0;
  border-bottom: 1px solid var(--gainsboro);
  white-space: nowrap;
  overflow-x: auto;
}

@media only screen and (max-width: 768px) {
  .articles-categories {
    padding: 20px 0;
  }
}

.articles-categories li {
  margin: 0 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .articles-categories li {
    margin: 0 24px;
  }
}

@media only screen and (max-width: 376px) {
  .articles-categories li {
    margin: 0 16px;
  }
}

.articles-categories li:hover {
  color: var(--blue);
}

.articles-categories li.active {
  color: var(--blue);
  text-decoration: underline;
}

.article-item {
  padding: 32px 24px;
  border-bottom: 1px solid var(--gainsboro);
}

@media only screen and (max-width: 376px) {
  .article-item {
    padding: 32px 16px;
  }
}

.article-item_info {
  font-size: 15px;
  font-family: "Lora", serif;
  color: var(--dark-blue);
  margin-bottom: 12px;
}

.article-item_date {
  margin-right: 24px;
}

.article-item_type {
  margin-left: 24px;
}

.article-item_bull {
  color: var(--blue);
}

.article-item_title a {
  font-size: 22px;
  color: var(--black);
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.article-item:hover {
  background: var(--light);
}

.article-item:hover .article-item_title a {
  color: var(--blue);
}

.articles-wrapper .link-button {
  margin: 32px 24px;
  padding: 5px 36px 5px 0;
  background: url("assets/icons/icon_arrow_scroll_down.svg") right no-repeat;
}

.articles-wrapper .link-button:hover {
  background: url("assets/icons/icon_arrow_scroll_down_hover.svg") right
    no-repeat;
}

.block-accordion {
  max-width: 852px;
  margin: 0 auto;
}

.accordion-item {
  position: relative;
  background: var(--light);
  padding: 20px 80px 20px 40px;
  border-radius: 16px;
  margin: 8px 0;
  cursor: pointer;
}

.accordion-title {
  font-size: 22px;
  font-weight: 600;
}

.accordion-content {
  max-height: 0;
  font-size: 18px;
  transition: all 0.3s ease-out;;
  overflow: hidden;
}

.accordion-item.expanded .accordion-content {
  margin: 20px 0 0;
  max-height: 120px;
}

@media only screen and (max-width: 1024px) {
  .accordion-item.expanded .accordion-content {
    max-height: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-title {
    font-size: 20px;
  }
  .accordion-content {
    font-size: 16px;
  }
  .accordion-item.expanded .accordion-content {
    max-height: 170px;
  }
}


@media only screen and (max-width: 568px) {
  .accordion-item.expanded .accordion-content {
    max-height: 250px;
  }
}

.accordion-item:after {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 20px;
  right: 40px;
  transition: transform 0.3s ease-out;
  background: url(assets/icons/icon_arrow_down.svg) no-repeat;
}

.accordion-item.expanded:after {
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

.accordion-item:hover,
.accordion-item.expanded {
  background: var(--light2);
}

footer {
  position: relative;
  background: var(--light);
}

footer .block-header {
  font-size: 24px;
}

@media only screen and (max-width: 376px) {
  footer .block-header {
    font-size: 20px;
  }
}

.footer-top {
  position: relative;
  display: flex;
  padding: 110px 0 150px;
  font-size: 13px;
  justify-content: space-between;
  border-bottom: 1px solid var(--gainsboro);
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .footer-top {
    padding: 150px 0 0;
  }
}

.footer-subscribe .block-header {
  text-align: left;
  padding-left: 25px;
}

.footer-subscribe_input-wrapper {
  display: flex;
}

.footer-subscribe_input-wrapper input {
  background: unset;
}

.footer-subscribe_input-wrapper button {
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .footer-subscribe .block-header {
    text-align: center;
    padding: 0 20px;
  }
  .footer-subscribe_input-wrapper {
    flex-direction: column;
  }
  .footer-subscribe_input-wrapper input {
    width: 100%;
    margin-bottom: 8px;
  }
  .footer-subscribe_input-wrapper button {
    width: 100%;
    margin: 0;
  }
}

.footer-follow .block-header {
  text-align: right;
}

@media only screen and (max-width: 1024px) {
  .footer-follow {
    margin-bottom: 50px;
  }

  .footer-follow .block-header {
    text-align: left;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-follow {
    margin: 60px 0;
  }
  .footer-follow .block-header {
    text-align: center;
    padding: 0 20px;
  }
  .footer-social_links {
    justify-content: center;
  }
  .footer-social_links li {
    margin: 0 15px;
  }
}

.footer-social_links {
  display: flex;
}

.footer-social_links li {
  margin-left: 24px;
}

.footer-social_links li a {
  display: block;
  width: 40px;
  height: 40px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  padding: 40px 0 0;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .footer-bottom,
  .footer-top {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 768px) {
  .footer-bottom,
  .footer-top {
    flex-direction: column;
  }
}

.footer-menu {
  display: flex;
  margin: 0 -28px;
}

.footer-top .footer-menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .footer-menu {
    margin: 20px 0 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-bottom {
    padding-top: 20px;
  }
  .footer-menu {
    flex-direction: column;
  }
  .footer-menu li {
    margin: 15px 0;
    text-align: center;
  }
  .footer-copyright {
    margin: 20px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer-bottom .footer-menu {
    display: none;
  }
  .footer-top .footer-menu {
    display: flex;
  }
}

.footer-menu a {
  margin: 0 28px;
  font-weight: 500;
  color: var(--black);
  text-decoration: none;
}

footer .footer-graphic_device {
  display: block;
  position: absolute;
  top: -60px;
  right: 300px;
}

@media only screen and (max-width: 1290px) {
  footer .footer-graphic_device {
    right: 168px;
  }
}

@media only screen and (max-width: 1024px) {
  footer .footer-graphic_device {
    right: 38px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    margin: 80px 0 0;
  }
  footer .footer-graphic_device {
    width: 240px;
    height: 240px;
    top: -80px;
    left: 50%;
    transform: translate(-50%);
  }
}

.sticky-menu {
  display: flex;
  background: var(--white);
  align-items: center;
  padding: 0 40px;
  position: fixed;
  top: 16px;
  width: 1215px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  border-radius: 80px;
  box-shadow: 0 8px 16px #041e5814;
  z-index: 99;
}

.sticky-menu .menu-open {
  display: none;
}

@media only screen and (max-width: 1290px) {
  .sticky-menu {
    width: 980px;
    height: 68px;
  }
}

@media only screen and (max-width: 1090px) {
  .sticky-menu {
    width: 610px;
    height: 68px;
  }
}

@media only screen and (max-width: 768px) {
  .sticky-menu .menu-open {
    display: block;
  }
  .sticky-menu {
    min-width: 300px;
    width: calc(100% - 76px);
    padding: 0 21px;
    height: 48px;
  }

  .sticky-menu .menu {
    display: none;
  }

  .sticky-menu .logo {
    margin: 0 auto;
  }
}

.logo img {
  width: 96px;
  height: 18px;
}

.sticky-menu .menu {
  margin-right: 0;
}

.sticky-menu .menu-open {
  top: 12px;
  left: 24px;
}

.article-page-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.backdrop-shadow {
  visibility: hidden;
  background: var(--black);
  transition: ease-in-out 0.6s;
  position: absolute;
  top: 0;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 899;
}

.backdrop-shadow.show {
  visibility: visible;
  opacity: 0.64;
}

.sidebar {
  display: flex;
  position: sticky;
  top: 0;
  height: 100vh;
  flex-direction: column;
  padding: 0 64px;
  background: var(--light);
  border-right: 1px solid var(--gainsboro);
}

.landing-wrapper .sidebar {
  justify-content: center;
  display: none;
}

@media only screen and (max-width: 768px) {
  .landing-wrapper .sidebar {
    display: flex;
  }
}

.sidebar-close {
  display: none;
  position: absolute;
  top: 24px;
  right: 24px;
}

.sidebar-close:hover svg path {
  fill: var(--navy);
}

@media only screen and (max-width: 1092px) {
  .sidebar {
    transition: transform ease-in-out 0.6s;
    transform: translate(-100%);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    width: 360px;
  }

  .sidebar.opened {
    transform: translate(0);
  }

  .sidebar-close {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 205px;
    padding: 0 40px;
  }
  .sidebar-close {
    top: 18px;
    right: 18px;
  }
}

.sidebar .logo {
  margin: 70px 0 30px;
}

@media only screen and (max-width: 376px) {
  .sidebar .logo {
    margin: 50px 0 10px;
  }
}

.sidebar .menu {
  width: auto;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 376px) {
  .sidebar .menu {
    margin: 10px 0 20px;
  }
}

.sidebar .menu li {
  margin: 15px 0;
}

.sidebar h3 {
  font-family: "Lora", serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--dark-blue);
  margin: 40px 0;
  padding: 10px 0 10px;
}

@media only screen and (max-width: 376px) {
  .sidebar h3 {
    margin: 30px 0 20px;
  }
}

.articles-topics {
  max-height: 300px;
  overflow-y: auto;
}

.articles-topics li {
  margin: 30px 0;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.articles-topics li a {
  color: var(--black);
  text-decoration: none;
}

.articles-topics li a:hover {
  color: var(--blue);
}

.articles-topics li.active a {
  color: var(--blue);
  text-decoration: underline;
}

@media only screen and (max-width: 376px) {
  .articles-topics li {
    font-size: 13px;
  }
}

.article-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

@media only screen and (max-width: 1092px) {
  .article-main {
    flex: auto;
    min-height: 100vh;
  }
}

.article-header {
  position: sticky;
  top: 0;
  background: var(--white);
  display: none;
  height: 70px;
  border-bottom: 1px solid var(--gainsboro);
}

@media only screen and (max-width: 376px) {
  .article-header {
    height: 50px;
  }
}

.article-header .logo {
  flex: 1;
  text-align: center;
}

.article-header .menu-open {
  display: block;
  left: 40px;
  top: 23px;
}

.article-header .menu-open:hover svg path {
  fill: var(--navy);
}

@media only screen and (max-width: 376px) {
  .article-header .menu-open {
    left: 20px;
    top: 13px;
  }
}

@media only screen and (max-width: 1092px) {
  .article-header {
    display: flex;
    align-items: center;
  }
}

.article-content {
  width: 680px;
  margin: 80px auto;
  word-wrap: break-word;
}

@media only screen and (max-width: 768px) {
  .article-content {
    width: auto;
    margin: 40px 0;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 376px) {
  .article-content {
    margin: 20px 0;
    padding: 0 20px;
  }
}

.article-content .article-item_info {
  font-size: 16px;
  margin-bottom: 18px;
}

.article-content .article-item_title,
.article-content h1 {
  font-size: 32px;
  color: var(--blue);
  font-weight: 600;
}

.article-content h3 {
  font-size: 20px;
  margin: 80px 0 40px;
}

.sources-list {
  margin: 50px 0;
  list-style: none;
  counter-reset: bracers-counter;
}

.sources-list li {
  margin-bottom: 30px;
  counter-increment: bracers-counter;
}

.sources-list li::marker {
  content: "[" counter(bracers-counter) "]";
  color: var(--blue);
  font-weight: bold;
}

.sources-list li a {
  color: var(--dark-blue);
}

.article-share {
  display: flex;
  align-items: center;
  margin: 60px 0;
}

@media only screen and (max-width: 492px) {
  .article-share {
    flex-direction: column;
    align-items: flex-start;
  }
  .article-share .article-share_links {
    margin: 20px -16px 0;
  }
}

.article-share .block-header {
  text-align: left;
  font-size: 24px;
  margin: 0 32px 0 0;
}

.article-share .article-share_links {
  display: flex;
  align-items: center;
}

.article-share .article-share_links li {
  width: 32px;
  height: 32px;
  margin: 0 16px;
}

.article-share .article-share_links li a img {
  width: 32px;
  height: 32px;
}

.article-page-wrapper .search-main {
  position: sticky;
  bottom: 20px;
  margin: 120px -20px;
}

@media only screen and (max-width: 1366px) {
  .article-page-wrapper .search-main {
    margin: 60px -20px;
  }
}

@media only screen and (max-width: 768px) {
  .article-page-wrapper .search-main {
    margin: 60px -10px;
  }
}

.article-page-wrapper .search-main input {
  width: 610px;
}

@media only screen and (max-width: 1366px) {
  .article-page-wrapper .search-main input {
    height: 40px;
  }
}

@media only screen and (max-width: 1366px) {
  .article-page-wrapper .form-button {
    padding: 11px 24px;
  }
}

.article-main .footer-bottom {
  flex-direction: column-reverse;
  padding-bottom: 20px;
  margin-top: auto;
  border-top: 1px solid var(--gainsboro);
}

.article-main .footer-menu {
  margin: 20px 0;
}
